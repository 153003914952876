import React, { useState, useEffect, useRef, useMemo } from 'react';
import { SideBar, Result, Space, Selector, SearchBar, Toast, Popup, Tag, Grid, List } from 'antd-mobile';
import { apis as _apis } from './preset';
import { UpOutline, DownOutline, LeftOutline ,CloseOutline} from 'antd-mobile-icons';
import get from 'lodash/get';
import './index.scss';
import cloneDeep from 'lodash/cloneDeep';

const getCount = (data, id, functions = []) => {
  const childId = (data || []).filter(item => item.parentCode === id && functions.indexOf(item.code) > -1).map(item => item.code);
  return childId.length;
};

const Collapse = ({ current: item, thirdList, onSelect,functionsCode }) => {
  const { chName } = item;
  const [open, setOpen] = useState(true);

  return <>
    <Space block className='adm-popup-body-r-title' justify='between'>
      <span>{chName}</span>
      {open && <UpOutline onClick={() => {
        setOpen(false)
      }} />}

      {!open && <DownOutline onClick={() => {
        setOpen(true)
      }} />}
    </Space>
    <div className={open ? "adm-popup-show" : "adm-popup-hide"}>
      {
        (thirdList || []).map((item) => {
          const { code, chName } = item;
          return <div
          className={`adm-popup-body-selector ${functionsCode.indexOf(code) > -1 ? "active" : ""}`}
            key={code}
            onClick={()=>onSelect(item)}>
            {chName}
          </div>
        })}

    </div>
  </>
}

export const RemoteData = ({ loader, options, onLoad, children }) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const onLoadRef = useRef(onLoad);
  onLoadRef.current = onLoad;
  
  useEffect(() => {
    Promise.resolve(loader(options))
      .then(data => {
        onLoadRef.current && onLoadRef.current(data);
        setData(data);
      })
      .catch(e => {
        console.error(e);
        setError(e);
      });
  }, [loader, options]);
  if (error) {
    return <Result status="error" title="获取数据发生错误" subTitle={error.message} />;
  }

  if (!data || get(data, 'length', 0) === 0) {
    return '-';
  }
  return children(data);
};

const SearchInput = ({ dataSource, onChange, functionsCode, level }) => {
  const [value, setValue] = useState(null);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const inputRef=useRef();

  useEffect(()=>{
    if(visible){
      inputRef.current?.focus()
    }
  },[visible]);

  return <div>
    <div onClick={()=>{
      inputRef.current?.focus()
    }}>
      <SearchBar className="adm-search-bar-readonly" readonly placeholder='搜索职能' onFocus={() => {
        setVisible(true);
      }} />
    </div>
    {visible && <div className="adm-action-search-wrapper">
      <div className="adm-action-search-body">
        <div className="adm-action-search-action">
          <div className="adm-action-search-back" onClick={() => {
            setValue(null);
            setData([]);
            setVisible(false);
          }}>
            <LeftOutline />
          </div>
          <div style={{ flex: '1' }}>
            <SearchBar ref={inputRef} placeholder='搜索职能' onChange={val => {
              setValue(val);
              apis.debounce((() => {
                return apis.searchfunctions(val, level).then(list => {
                  setData(list);
                });
              })(), 500);
            }} />
          </div>
          <div className="action-button-primary" onClick={() => {
            return apis.searchfunctions(value, level).then(list => {
              setData(list);
            });
          }}>
            搜索
          </div>
        </div>
        {data && data.length > 0 && <List>
          {data.map((item, index) => (
            <List.Item clickable={false} key={item.value} onClick={(e) => {
              if (functionsCode.indexOf(item.value) > -1) return;
              onChange && onChange(_apis.getFunctionById(dataSource, item.value));
              setValue(null);
              setData([]);
              setVisible(false);
            }}>{item.label}</List.Item>
          ))}
        </List>}
      </div>
    </div>}
  </div>
};


export const apis = _apis;

export const DisplayFunction = ({ id, children }) => {
  if (!Array.isArray(id)) {
    id = [id];
  }
  return (
    <RemoteData loader={apis.getFunction} options={id}>
      {children}
    </RemoteData>
  );
};


const DisplayFunctionCount = ({ name, count }) => {
  return <div className='adm-popup-body-ellipsis'>
    <span className="adm-ellipsis">{name}</span>
    {count > 0 && <span className='adm-popup-count'>({count})</span>}
  </div>
}


const FunctionSelectField = ({ showSelectRender, onClose, dataSource, labelInValue, size, defaultValue, onChange, selectLevel, ...props }) => {
  const selectDomRef = useRef();
  const [functionHeight, setFunctionHeight] = useState(0);

  const [functions, setfunctions] = useState(
    (() => {
      if (!Array.isArray(defaultValue)) return [];
      const _default = labelInValue ? defaultValue.map(item => get(item, 'value')) : defaultValue;
      return _default.map((id, index) => {
        const _filter = apis.getFunctionById(dataSource, id) || {};

        if (_filter) {
          return {
            label: _filter.chName,
            value: _filter.code,
            parentCode: _filter.parentCode,
            storey: _filter.storey
          };
        }
        return defaultValue[index];
      });
    })()
  );

  const firstList = useMemo(() => {
    return apis.getLeftList(dataSource);
  }, [dataSource]);
  const [selectedKeys, setSelectedKeys] = useState(get(firstList, '[0].code'));

  const secondList = useMemo(() => {
    return apis.getChildById(dataSource, selectedKeys);
  }, [dataSource, selectedKeys]);



  const appendFunc = item => {
    const { chName: label, storey, parentCode, code: value } = item;

    let _functions = cloneDeep(functions);
    if (size === 1) {
      setfunctions([{ label, storey, parentCode, value }]);
      onClose && onClose();
      onChange([{ label, storey, parentCode, value }]);
      return;
    }
    if (_functions.length >= size) {
      Toast.show({
        icon: 'fail',
        content: `最多选择${size}个`,
      });
      return;
    }
    if (storey === '2') {
      _functions = _functions.filter(item => item.parentCode !== value);
    }
    if (storey === '1') {
      _functions = _functions.filter(item => item.value.slice(0, 3) !== value);
    }

    _functions.push({ label, storey, parentCode, value });

    setfunctions([..._functions]);
  };

  const removeFunc = (current = {}) => {
    setfunctions(list => {
      let _list = cloneDeep(list);
      if (current.storey === '2') {
        _list = list.filter(item => item.parentCode !== current.code);
      }
      if (current.storey === '1') {
        _list = list.filter(item => item.value.slice(0, 3) !== current.code);
      }
      const index = _list.map(item => get(item, 'value')).indexOf(current.code);
      _list.splice(index, 1);
      return _list;
    });
  };
  const functionsCode = useMemo(() => {
    return functions.map(item => get(item, 'value', ''));
  }, [functions]);

  useEffect(() => {
    if (get(defaultValue, 'length', 0) > 0) {
      const _code = labelInValue ? get(defaultValue, '[0].value') : defaultValue[0];

      if (_code) {
        setSelectedKeys(_code.slice(0, 3));
      }
    }
  }, [defaultValue, dataSource, labelInValue]);

  useEffect(() => {
    setTimeout(() => {
      const dom = selectDomRef.current;
      const _height = dom ? dom.clientHeight : 0;
      setFunctionHeight(_height);
    }, 200);
}, [functions])

  return (<Popup  {...props}
    onMaskClick={onClose} bodyClassName="function" mask={true}>
    <div className='adm-popup-function-wrapper'>
      <div className="adm-popup-title">选择期望职位</div>
      <div className="adm-popup-search">
        <SearchInput
          dataSource={dataSource}
          level={selectLevel}
          functionsCode={functionsCode}
          labelInValue={labelInValue}
          onChange={value => {
            appendFunc(value);
            setSelectedKeys(value.parentCode ? value.parentCode : value.code);
          }} />
      </div>

      <Grid columns={5} gap={8} className="adm-popup-body-wrapper" style={{ height: `calc(100vh - 88px - 64px - ${functionHeight}px)` }}>
        <Grid.Item span={2} className="adm-popup-body-left">
          <SideBar activeKey={selectedKeys} onChange={(item) => {
            setSelectedKeys(item);
          }}>
            {firstList.map((item) => {
              const count = getCount(dataSource, item.code, functionsCode);
              return <SideBar.Item
                key={item.code}
                title={<DisplayFunctionCount
                  id={item.code}
                  count={count}
                  name={item.chName} />}>

              </SideBar.Item>
            }
            )}
          </SideBar>
        </Grid.Item>
        <Grid.Item span={3} className="adm-popup-body-right">
          {(secondList || []).map((item) => {
            const { code } = item;
            const thirdList = apis.getChildById(dataSource, code);

            return (
              <div key={code} className="adm-popup-body-collapse">
                <Collapse
                functionsCode={functionsCode}
                  onSelect={(v) => {
                    const checked = functionsCode.indexOf(code) > -1;
                    if (!checked) {
                      appendFunc(v);
                    } else {
                      removeFunc(v);
                    }
                  }} 
                  current={item} 
                  thirdList={thirdList} />
              </div>

            )
          })}

        </Grid.Item>
      </Grid>
      <div className="adm-popup-footer"  ref={selectDomRef}>
      {showSelectRender?<>
        <div className="adm-popup-selects">
          <div className='adm-popup-select-length' style={{
            whiteSpace: 'nowrap'
          }}>已选 {size > 1 ? <><span className='primary-color'>{functions.length}</span>/{size}</> : null}
          </div>
          <div flex={1}>
            <Space wrap>
              {functions.map((item, index) => {
                const { value:code, label:chName }=item
                return (
                  <Space
                    style={{ '--gap': "4px" }}
                    className='adm-popup-tag-active'
                    align='center'
                    justify='center'
                    key={code}
                  >
                    <span>{chName}</span>
                    {size > 1 && <span onClick={(event) => {
                      removeFunc({...item,code,chName});
                    }}>
                      <CloseOutline />
                    </span>}
                  </Space>
                );
              })}
            </Space>
          </div>
        </div>
        <Grid columns={2} gap={8} >
          <Grid.Item span={1}>
            <div className="adm-popup-half-header-close" onClick={(event) => {
              onClose && onClose();
            }}>
              取消
            </div>
          </Grid.Item>
          <Grid.Item span={1}>
            <div className="adm-popup-header-sure" onClick={(event) => {
              onClose && onClose();
              onChange(functions);
            }}>
              确认
            </div>

          </Grid.Item>
        </Grid>
      </>:
        <div className="adm-popup-header-close" onClick={(event) => {
            onClose && onClose();
          }}>
            取消
        </div>}
        </div>
    </div>
  </Popup>
  );
};


const FunctionSelect = ({ onChange, labelInValue, ...props }) => {
  return <RemoteData loader={apis.getAllList}>
    {dataSource => {
      return (
        <FunctionSelectField
          {...props}
          dataSource={dataSource}
          labelInValue={labelInValue}
          onChange={value => {
            let changeValue = [],
              valueObj = [];
            if (get(value, 'length', 0) > 0) {
              valueObj = value.map(item => ({ label: get(item, 'label'), value: get(item, 'value') }));
              changeValue = labelInValue ? valueObj : value.map(item => get(item, 'value'));
            }

            onChange && onChange(changeValue, valueObj);
          }}
        />
      );
    }}
  </RemoteData>
};

FunctionSelect.defaultProps = {
  showSelectRender: false,
  title: '请选择职能',
  size: 1,
  defaultValue: [],
  selectLevel: 1,
  labelInValue: true,
  onChange: () => { }
};

export default FunctionSelect
