import React, { useState, useEffect, useRef, useMemo } from 'react';
import { SideBar, Result, Space, Selector, SearchBar, Toast, Popup, Tag, Grid, List, Ellipsis } from 'antd-mobile';
import { apis as _apis } from './preset';
import { CloseOutline, LeftOutline } from 'antd-mobile-icons';
import get from 'lodash/get';
import './index.scss';
import cloneDeep from 'lodash/cloneDeep';

const getCount = (data, id, industries = []) => {
  const childId = (data || []).filter(item => item.parentCode === id && industries.indexOf(item.code) > -1).map(item => item.code);
  return childId.length;
};

export const RemoteData = ({ loader, options, onLoad, children }) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const onLoadRef = useRef(onLoad);
  onLoadRef.current = onLoad;
  useEffect(() => {
    Promise.resolve(loader(options))
      .then(data => {
        onLoadRef.current && onLoadRef.current(data);
        setData(data);
      })
      .catch(e => {
        console.error(e);
        setError(e);
      });
  }, [loader, options]);
  if (error) {
    return <Result status="error" title="获取数据发生错误" subTitle={error.message} />;
  }

  if (!data || get(data, 'length', 0) === 0) {
    return '-';
  }
  return children(data);
};

const SearchInput = ({ dataSource, onChange, industriesCode, level }) => {
  const [value, setValue] = useState(null);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const inputRef=useRef();
  useEffect(()=>{
    if(visible){
      inputRef.current?.focus()
    }
  },[visible]);

  return <div>
    <div onClick={()=>{
      inputRef.current?.focus()
    }}>
    <SearchBar className="adm-search-bar-readonly" readonly placeholder='搜索行业' onFocus={() => {
      setVisible(true);
    }} />
    </div>
    {visible && <div className="adm-action-search-wrapper">
      <div className="adm-action-search-body">
        <div className="adm-action-search-action">
          <div className="adm-action-search-back" onClick={() => {
            setValue(null);
            setData([]);
            setVisible(false);
          }}>
            <LeftOutline />
          </div>
          <div style={{ flex: '1' }}>
            <SearchBar ref={inputRef} placeholder='搜索行业' onChange={val => {
              setValue(val);
              apis.debounce((() => {
                return apis.searchIndustries(val, level).then(list => {
                  setData(list);
                });
              })(), 500);
            }} />
          </div>
          <div className="action-button-primary" onClick={() => {
            return apis.searchIndustries(value, level).then(list => {
              setData(list);
            });
          }}>
            搜索
          </div>
        </div>
        {data && data.length > 0 && <List>
          {data.map((item, index) => (
            <List.Item clickable={false} key={item.value} onClick={(e) => {
              if (industriesCode.indexOf(item.value) > -1) return;
              onChange && onChange(_apis.getIndustryById(dataSource, item.value));
              setValue(null);
              setData([]);
              setVisible(false);
            }}>{item.label}</List.Item>
          ))}
        </List>}
      </div>
    </div>}
  </div>
};


export const apis = _apis;

export const DisplayIndustry = ({ id, children }) => {
  if (!Array.isArray(id)) {
    id = [id];
  }
  return (
    <RemoteData loader={apis.getIndustry} options={id}>
      {children}
    </RemoteData>
  );
};

const DisplayIndustryCount = ({ name, count }) => {
  return <div className='adm-popup-body-ellipsis'>
    <span className="adm-ellipsis">{name}{count?`(${count})`:null}</span>
  </div>
}
export { default as preset } from './preset';

const IndustrySelectField = ({ title, showSelectRender, onClose, dataSource, labelInValue, size, defaultValue, onChange, selectLevel, ...props }) => {
  const selectDomRef = useRef();
  const [industryHeight, setIndustryHeight] = useState();
  const [industries, setIndustries] = useState(
    (() => {
      if (!Array.isArray(defaultValue)) return [];
      const _default = labelInValue ? defaultValue.map(item => get(item, 'value')) : defaultValue;

      return _default.map((id, index) => {
        const _filter = apis.getIndustryById(dataSource, id) || {};

        if (_filter) {
          return {
            label: _filter.chName || '-',
            value: id,
            parentCode: _filter.parentCode,
            level: _filter.level
          };
        }
        return defaultValue[index];
      });
    })()
  );
  const [selectedKeys, setSelectedKeys] = useState();

  const industriesCode = useMemo(() => {
    return industries.map(item => get(item, 'value', ''));
  }, [industries]);

  const menuList = useMemo(() => {
    return apis.getLeftList(dataSource);
  }, [dataSource]);

  const rightList = useMemo(() => {
    return apis.getRightList(dataSource, selectedKeys);
  }, [selectedKeys, dataSource]);

  const appendIndustry = async obj => {
    const { chName: label, level, parentCode, code: value } = obj;

    let _Industrys = cloneDeep(industries);
    if (size === 1) {
      setIndustries([{ label, level, parentCode, value }]);
      onChange([{ label, level, parentCode, value }]);
      return;
    }
    if (_Industrys.length >= size) {
      Toast.show({
        icon: 'fail',
        content: `最多选择${size}个`,
      });
      return;
    }

    if (level === '0') {
      _Industrys = _Industrys.filter(item => item.parentCode !== value);
    }

    _Industrys.push({ label, level, parentCode, value });

    setIndustries([..._Industrys]);
  };
  const removeIndustry = (current = {}) => {
    setIndustries(list => {
      let _list = cloneDeep(list);
      if (current.level === '0') {
        _list = list.filter(item => item.parentCode !== current.code);
      }
      const index = _list.map(item => get(item, 'value')).indexOf(current.code);
      _list.splice(index, 1);
      return _list;
    });
  };
  useEffect(() => {
    if (get(defaultValue, 'length', 0) > 0) {
      const _filter = apis.getIndustryById(dataSource, labelInValue ? get(defaultValue, '[0].value') : defaultValue[0]);
      if (_filter) {
        setSelectedKeys(_filter.parentCode ? _filter.parentCode : _filter.code);
        return;
      }
    }
    setSelectedKeys(get(menuList, '[0].code'));
  }, [menuList, defaultValue, dataSource, labelInValue]);


  const selectObj = useMemo(() => {
    const filter = apis.getIndustryById(dataSource, selectedKeys);
    return filter
  }, [selectedKeys, dataSource])

  useEffect(() => {
    setTimeout(() => {
      const dom = selectDomRef.current;
      const _height = dom ? dom.clientHeight : 0;
      setIndustryHeight(_height);
    }, 200);
  }, [industries])

  return (<Popup  {...props}
    onMaskClick={onClose} bodyClassName="industry" mask={true}>
    <div className='adm-popup-industry-wrapper'>
      <div className="adm-popup-title">{title}</div>
      <div className="adm-popup-search">
        <SearchInput
          dataSource={dataSource}
          level={selectLevel}
          industriesCode={industriesCode}
          labelInValue={labelInValue}
          onChange={value => {
            appendIndustry(value);
            setSelectedKeys(value.parentCode ? value.parentCode : value.code);
          }} />
      </div>
      <Grid columns={5} gap={12} className="adm-popup-body-wrapper">
        <Grid.Item span={2} className="adm-popup-body-left"
                   style={{
                     height: `calc(100vh - 88px - 64px - 16px - ${industryHeight}px)`
                   }}>
          <SideBar activeKey={selectedKeys} onChange={(item) => {
            setSelectedKeys(item);
          }}>
            {menuList.map((item) => {
              const count = getCount(dataSource, item.code, industriesCode);
              return <SideBar.Item
                key={item.code}
                title={<DisplayIndustryCount
                  id={item.code}
                  count={count}
                  name={item.chName} />}>

              </SideBar.Item>
            }
            )}
          </SideBar>
        </Grid.Item>
        <Grid.Item span={3} className="adm-popup-body-right">
          <div className='adm-popup-body-right-title'>{get(selectObj, 'chName')}</div>

          {(rightList || []).map((item) => {
            const { code, chName } = item;
            return (
              <div
                className={`adm-popup-body-selector ${industriesCode.indexOf(code) > -1 ? "active" : ""}`}
                key={code}
                onClick={() => {
                  const checked = industriesCode.indexOf(code) > -1;
                  if (!checked) {
                    appendIndustry(item);
                  } else {
                    removeIndustry(item);
                  }
                }}>
                {chName}
              </div>

            )
          })}

        </Grid.Item>
      </Grid>
      <div className="adm-popup-footer" ref={selectDomRef}>
      {showSelectRender?<>
            <div className="adm-popup-selects">
              <div className='adm-popup-select-length' style={{
                whiteSpace: 'nowrap'
              }}>已选 {size > 1 ? <><span className='primary-color'>{industries.length}</span>/{size}</> : null}
              </div>
              <div flex={1}>
                <Space wrap>
                  {industries.map((item, index) => {
                    const { value:code, label:chName }=item
                    return (
                        <Space
                            style={{ '--gap': "4px" }}
                            className='adm-popup-tag-active'
                            align='center'
                            justify='center'
                            key={code}
                        >
                          <span>{chName}</span>
                          {size > 1 && <span onClick={(event) => {
                            removeIndustry({...item,code,chName});
                          }}>
                      <CloseOutline />
                    </span>}
                        </Space>
                    );
                  })}
                </Space>
              </div>
            </div>
        <Grid columns={2} gap={8} >
          <Grid.Item span={1}>
            <div className="adm-popup-half-header-close" onClick={(event) => {
              onClose && onClose();
            }}>
              取消
            </div>
          </Grid.Item>
          <Grid.Item span={1}>
            <div className="adm-popup-header-sure" onClick={(event) => {
              onClose && onClose();
              onChange(industries);
            }}>
              确认
            </div>

          </Grid.Item>
        </Grid>
      </>:
        <div className="adm-popup-header-close" onClick={(event) => {
            onClose && onClose();
          }}>
            取消
        </div>}
      </div>
    </div>
  </Popup>
  );
};


const IndustrySelect = ({ onChange, labelInValue, ...props }) => {
  return <RemoteData loader={apis.getAllList}>
    {dataSource => {
      return (
        <IndustrySelectField
          {...props}
          dataSource={dataSource}
          labelInValue={labelInValue}
          onChange={value => {
            let changeValue = [],
              valueObj = [];
            if (get(value, 'length', 0) > 0) {
              valueObj = value.map(item => ({ label: get(item, 'label'), value: get(item, 'value') }));
              changeValue = labelInValue ? valueObj : value.map(item => get(item, 'value'));
            }

            onChange && onChange(changeValue, valueObj);
          }}
        />
      );
    }}
  </RemoteData>
};

IndustrySelect.defaultProps = {
  showSelectRender: true,
  title: '选择期望行业',
  size: 1,
  defaultValue: [],
  selectLevel: 1,
  labelInValue: true,
  onChange: () => { }
};

export default IndustrySelect
