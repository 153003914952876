const accountToken = {
    get token() {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('token');
    },
    set token(value) {
        throw new Error('本系统不允许写token');
    }
};

export default accountToken;
