import '@kne/react-form-antd-mobile/dist/index.css';
import {preset as fetchPreset} from '@kne/react-fetch';
import {preset as industryPreset} from './modules/IndustrySelect';
import {preset as cityPreset} from './modules/CitySelect';
import {preset as functionPreset} from './modules/FunctionSelect';
import axios from 'axios';
import {Toast, Result, Empty, DotLoading} from 'antd-mobile'
import {interceptors} from '@kne/react-form-antd-mobile';
import dayjs from 'dayjs';
import accountToken from './util/accountToken';

let industryCache,cityCathe,funcionCache;

export const MINI_STATIC_URL= `https://fat-dev-static.oss-cn-shanghai.aliyuncs.com`;

const domain = [window.location.hostname].includes('localhost') ? '' : MINI_STATIC_URL;

cityPreset({
    loadData: (() => {
        return async () => {
            if (!cityCathe) {
                cityCathe = await axios.get(`${domain}/newCity.json`).then((response) => {
                    return response.data;
                });
            }
            return cityCathe;
        };
    })()
});
industryPreset({
    loadData: (() => {
        return async () => {
            if (!industryCache) {
                industryCache = await axios.get(`${domain}/industry.json`).then((response) => {
                    return response;
                });
            }
            return industryCache;
        };
    })()
});
functionPreset({
    loadData: (() => {
        return async () => {
            if (!funcionCache) {
                funcionCache = await axios.get(`${domain}/function.json`).then((response) => {
                    return response;
                });
            }
            return funcionCache;
        };
    })()
});

interceptors.input.use('picker-single', (value) => {
    return value ? [value] : [];
});

interceptors.output.use('picker-single', (value) => {
    return value ? value[0] : null;
});

interceptors.input.use('picker-salary', (value) => {
    return value ? [+value] : [];
});

interceptors.output.use('picker-salary', (value) => {
    return value ? +value[0] : null;
});


interceptors.input.use('date', (value) => {
    return value ? new Date(value) : null;
});

interceptors.output.use('date', (value) => {
    return value ? dayjs(value).format('YYYY-MM') : null;
});


export const ajax = axios.create({
    validateStatus: function () {
        return true;
    }
});

ajax.interceptors.request.use((config) => {
    config.headers = {
        'X-CNDIDATE-TOKEN': accountToken.token
    };
    if (config.method.toUpperCase() !== 'GET') {
        config.headers['Content-Type'] = 'application/json';
    }
    return config;
});

ajax.interceptors.response.use((response) => {
    if (response.status !== 200 || response.data.code !== 0) {
        Toast.show({
            content: response.data.msg || '请求发生错误',
        });
    }
    if (response.data.code === 401) {
        window.wx.miniProgram.navigateTo({
            url: '/pages/index/index'
        });
    }
    return response;
});


fetchPreset({
    ajax,
    loading: <DotLoading style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)'}}/>,
    error: <Result status="error" title="请求发生错误"/>,
    empty: <Empty/>,
    transformResponse: (response) => {
        const {data} = response;
        response.data = {
            code: data.code === 0 ? 200 : data.code, msg: data.msg, results: data.data
        };
        return response;
    }
});
