export const apis = {
  loadData: (() => {
    let cache;
    return () => {
      if (!cache) {
        cache = import('./industry.json');
      }
      return cache;
    };
  })(),
  getAllList: () => {
    return apis.loadData().then(({ data }) => {
      return data;
    });
  },
  getIndustry: id => {
    return apis.loadData().then(({ data }) => {
      if (Array.isArray(id)) {
        return id.map(i => data.find(item => item.code === i) || { chName: '-' });
      }
      if (typeof id === 'string') {
        return data.find(item => item.code === id);
      }
      return null;
    });
  },
  searchIndustries: (value, level) => {
    if (!value) {
      return Promise.resolve([]);
    }
    return apis.loadData().then(({ data }) => {
      return data
        .filter(item => item.code !== '000' && (level === 2 ? +item.level <= level - 1 : level === +item.level))
        .filter(item => {
          return ['pinyin', 'chName', 'shortName', 'enName'].some(name => {
            return item[name].toUpperCase().indexOf(value.toUpperCase()) > -1;
          });
        })
        .map(item => {
          return {
            label: item.chName,
            value: item.code,
            item
          };
        });
    });
  },
  getLeftList: data => data.filter(item => item.level === '0' && item.code !== '000'),
  getRightList: (data, id) => data.filter(item => item.level === '1' && item.parentCode === id),
  getChildById: (data, id) => data.filter(item => item.level === '1' && item.parentCode === id),
  getIndustryById: (data, id) => {
    if (Array.isArray(id)) {
      return id.map(i => data.find(item => item.code === i) || { chName: '-' });
    }
    if (typeof id === 'string') {
      return data.find(item => item.code === id);
    }
    return null;
  },
  getIndustryByName: (data, name) => {
    if (Array.isArray(name)) {
      return data.filter(item => {
        return name.some(i => item.chName === i);
      });
    }
    if (typeof name === 'string') {
      return data.find(item => item.chName === name);
    }
    return null;
  },
  debounce: (fn, wait, immediate) => {
      let timer;
      return function () {
          if (timer) clearTimeout(timer);
          if (immediate) {
              // 如果已经执行过，不再执行
              var callNow = !timer;
              timer = setTimeout(() => {
                  timer = null;
              }, wait)
              if (callNow) {
                  fn.apply(this, arguments)
              }
          } else {
              timer = setTimeout(() => {
                  fn.apply(this, arguments)
              }, wait);
          }
      }
  }
};

const preset = options => {
  Object.assign(apis, options);
};

export default preset;
