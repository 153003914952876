import {Routes, Route} from 'react-router-dom';
import {pages} from './pages';
import './common/font/iconfont.css';

const {
    Home,
    Resume,
    ResumeBasic,
    ResumeExpect,
    ResumeEducation,
    ResumeWork,
    ResumeLanguage,
    ResumeProject,
    ResumeTraining,
    ResumeCertificate,
    ResumeSelfRemark,
    Industry,
    ResumeSimple,
    Fat,
    LinkToH5,
    ResumeTemplateCreate
} = pages;

function App() {
    return (<Routes>
        <Route index element={<Home/>}/>
        <Route path="resume" element={<Resume/>}/>
        <Route path="resume/basic" element={<ResumeBasic/>}/>
        <Route path="industry" element={<Industry/>}/>
        <Route path="resume/expect" element={<ResumeExpect/>}/>
        <Route path="resume/education" element={<ResumeEducation/>}/>
        <Route path="resume/work" element={<ResumeWork/>}/>
        <Route path="resume/language" element={<ResumeLanguage/>}/>
        <Route path="resume/project" element={<ResumeProject/>}/>
        <Route path="resume/training" element={<ResumeTraining/>}/>
        <Route path="resume/certificate" element={<ResumeCertificate/>}/>
        <Route path="resume/selfRemark" element={<ResumeSelfRemark/>}/>
        <Route path="resume/simple" element={<ResumeSimple/>}/>
        <Route path="resume/createTemplate" element={<ResumeTemplateCreate/>}/>
        <Route path="fat" element={<Fat/>}/>
        <Route path="linkToH5" element={<LinkToH5/>}/>
    </Routes>);
}

export default App;
