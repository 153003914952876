import {withDecoratorList} from '@kne/react-form-antd-mobile';
import {useMemo} from 'react';
import {Ellipsis} from 'antd-mobile';
import {default as _FuncitonSelect,DisplayFunction} from './Component';
import { get } from 'lodash';
import {apis as _apis} from "./Component/preset";

export const apis = _apis;
export { default as preset } from './Component/preset';

export const FuncitonSelect =_FuncitonSelect;

export default withDecoratorList(({render, placeholder, value, showPopup}) => {
  const label = useMemo(() => {
    if (!value) {
        return '';
    }
    if (value.length === 0) {
        return '';
    }

    return <div className="select-input" >
      <Ellipsis direction='end' content={value.map(item => get(item,"label")).join(",")} />
    </div>;
}, [value]);
    return render({
        label,
        value,
        placeholder,
        onClick: showPopup
    });
})(({maxSize = 1, value, ...props}) => <_FuncitonSelect {...props} defaultValue={value} size={maxSize}/>);
