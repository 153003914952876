import CitySelect, {DisplayCity} from './Component';
import {withDecoratorList} from '@kne/react-form-antd-mobile';
import {useMemo} from 'react';
import {Space} from 'antd-mobile';
import {apis as _apis} from "./Component/preset";

export const apis = _apis;
export { default as preset } from './Component/preset';

export default withDecoratorList(({render, placeholder, value, showPopup}) => {
    const label = useMemo(() => {
        if (!value) {
            return '';
        }
        if (value.length === 0) {
            return '';
        }
        return <Space>{value.map((id) => <DisplayCity key={id} id={id}>{({city, parent}) => {
            if (!city) {
                return id;
            }
            return parent ? parent.name + '·' + city.name : city.name;
        }}</DisplayCity>)}</Space>;
    }, [value]);
    return render({
        label,
        value,
        placeholder,
        onClick: showPopup
    });
})(({maxSize = 1, value, ...props}) => <CitySelect {...props} defaultValue={value} size={maxSize}/>);
